import React from 'react'
import Link from 'gatsby-link'

// Import typefaces
import 'typeface-montserrat'
import 'typeface-merriweather'

import profilePic from './profile-pic.jpg'
import { rhythm } from '../utils/typography'

import ThemeToggle from './ThemeToggle'

class Bio extends React.Component {
  render() {
    return (
      <div
        style={{
          display: 'flex',
          marginBottom: rhythm(2.5),
        }}
      >
        <img
          src={profilePic}
          alt={`Ritesh Gupta`}
          style={{
            marginRight: rhythm(1 / 2),
            marginBottom: 0,
            width: rhythm(2),
            height: rhythm(2),
          }}
        />
        <p>
          Hi, I’m <strong>Ritesh Gupta</strong>, iOS Engineer from India 🇮🇳. 
          Here, I mainly write about Swift and iOS app development. 
          <br/>
          <a href="https://twitter.com/_riteshhh"
            style={{
              boxShadow: 'none',
            }}
          >
          twitter → @_riteshhh.
          </a>
          <br/>
          <Link
            style={{
              boxShadow: 'none',
            }}
            to={'/about'}
          >
            More about me.  
          </Link>
        </p>
      </div>
    )
  }
}

export default Bio
